export const SPECIFICATION_TEASER = {
  teaser: [
    {
      copy: 'XML-based extension for Atom and RSS feeds to express simple chapter structures',
      icon: '../images/icons/chapter-marks--white.png',
      link: '/podlove-simple-chapters',
      title: 'Podlove Simple Chapters',
    },
    {
      copy: 'Atom-based convention on how to convey web player information to a podcast client allowing deep linking into media content ',
      icon: '../images/icons/deep-link--white.png',
      link: '/podlove-deep-linking',
      title: 'Podlove Deep Linking',
    },
    {
      copy: 'Best practice on how to relate to alternate feeds from within one podcast feed to give clients and users a choice of media formats and/or delivery methods',
      icon: '../images/icons/alternate-feed--white.png',
      link: '/podlove-alternate-feeds',
      title: 'Podlove Alternate Feeds',
    },
    {
      copy: 'Using the RFC 5005 specification to span podcast feeds over multiple pages to keep subscription feeds small while retaining access to old episodes for the user to access.',
      icon: '../images/icons/paged-feed--white.png',
      link: '/podlove-paged-feeds',
      title: 'Paged Feeds',
    },
  ]
}