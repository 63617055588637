import React from 'react'

import { SPECIFICATION_TEASER } from '../content/specificationTeaser'
import { getRandomDarkColor } from '../helpers/colors'

const PlayerBenefits = () => (
  <div
    className="
      md:grid
      md:grid-cols-3
      md:gap-24
      font-sans
      px-12
      pb-40
      w-screen
      max-w-7xl
      text-blue-800
      tracking-wide
    "
  >
    <div
      className="
        md:pt-28
        row-span-2
      "
    >
      <p>Once there is significant support for some common behaviour, we try to describe the necessary data formats, network protocols or practices in easy to understand and easy to implement documents and publish them here. These specifications have been written so far:</p>
    </div>
    { SPECIFICATION_TEASER.teaser.map(item =>
      <a
        key={JSON.stringify(item)}
        href={item.link}
      >
        <div
          className="
            mt-12
            md:mt-0
            w-full
          "
        >
          <div
            className={`
              bg-${getRandomDarkColor()}
              rounded-md
              h-10
              my-4
              w-10
            `}
          >
            <img src={item.icon} />
          </div>
          <h3
            className={`
              font-bold
              pb-4
              text-xl
              hover:text-${getRandomDarkColor()}
            `}
          >{item.title}</h3>
          <p>{item.copy}</p>
        </div>
      </a>
    )}
  </div>
)

export default PlayerBenefits