import React from 'react'

import SpecificationTeaser from '../components/specificationTeaser'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Seo from '../components/seo'

const About = () => (
  <Layout
    subHeaderTitle={'Podcasting Specifications'}
  >
    <Seo title="Specifications" />
    <Hero
      copy="The Podlove Project wants to drive Podcast development by providing community-backed specifications that solve real problems of podcasters, podcast client and podcast users alike."
      headline="Podcasting Specifications"
      showIllustration={false}
    />
    <SpecificationTeaser />
  </Layout>
)

export default About